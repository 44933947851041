<template>
  <div class="mobile-form">
    <form @submit.prevent="emailVerify">
      <div class="form-group">
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        <div>
          <p class="verification-heading">{{ $t("Verify Email Id") }}</p>
          <div class="email-verification">
            <p class="vlive-text">
              {{ $t("Please Enter 6 Digit OTP sent to your Email") }}
            </p>
          </div>
        </div>

        <div class="vlive-form">
          <p class="vlive-user-input">{{ userEmail }}</p>
          <P class="change-button" @click="changeFrom('Email')">{{
            $t("Change")
          }}</P>
        </div>

        <div class="form-control">
          <PinLayout
            ref="pinLayout"
            value=""
            pinCount="6"
            @onChange="updatePin"
            @onCompleted="pinCompleted"
            class="pinboxes"
          />
        </div>
        <div class="form-control">
          <p v-if="timeRemaining" class="mb-10 mt-10 subtitle text-center">
            {{ $t("Resend Code in") }}
            <span style="color: white; font-size: 14px"
              >00:{{ timeRemaining }}</span
            >
          </p>
          <p @click="resend" v-else class="mb-10 subtitle text-center">
            {{ $t("Didn't receive OTP?") }}
            <span style="color: white; font-size: 14px">{{
              $t("Resend")
            }}</span>
          </p>
        </div>
      </div>

      <div class="login-button">
        <button class="button-primary signup" type="submit">
          {{ $t("SIGNUP") }}
        </button>
      </div>
    </form>
  </div>
</template>
  
<script>
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import { mapGetters, mapActions } from "vuex";
import { _providerId } from "../../../../provider-config";
export default {
  props: ["input", "formData"],
  data() {
    return {
      userPin: [],
      profilePin: "",
      formError: null,
      localDisplayLang: null,
      userEmail: "",
      timeRemaining: "60",
      otpTimer: null,
      inputType: "'text'",
    };
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
    timeRemaining: {
      handler(newValue, oldValue) {
        if (newValue === "") {
          this.clearOtpInterval(newValue);
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapGetters(["country", "appConfig"]),
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("login-response", (response) => {

      localStorage.setItem("sessionToken", response.success);
      eventBus.$emit("preferred-lang-visibility", true);
      let currentLanguage = this.getCurrentLanguageUrlBase();
        let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      if (currentLanguage == defaultLanguage) {
          currentLanguage = null;
        }

      if ( window.selectedPlanInfomationRouting ) {
            this.$router.push({ name: "Home", params: { lang: currentLanguage } });

            eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
            window.selectedPlanInfomationRouting = "";
        } else {
          this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : this.$route.path });
        }

        window.storeAfterLoginPath = "";

    });
  },
  mounted() {
    this.setupOtpInterval();
    this.resend = this.debounce(this.resend, 3000);
    this.setupForm();
    this.setupPin();
  },
  methods: {
    ...mapActions(["getCaptcha", "newEmailconfirm", "signup", "resendLink", "listProfiles", "parentalControlStatus"]),
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    resendCaptcha() {
      if (this.appConfig.featureEnabled.isCaptchaEnabled) {
        let template = {
          input: this.inputtype === "Email" ? "email" : "mobile",
          formType: "verifyResendCaptcha",
          mobileno: this.userEmail ? this.userEmail : "",
        };

        this.$emit("change", template);
      } else {
        this.resend();
      }
    },

    resend() {
      this.clearOtpInterval();
      this.$refs.pinLayout.clearOtpBoxes();
      let payload = {
        email: this.input,
      };
      this.setupOtpInterval();
      this.timeRemaining = 60;

      eventBus.$emit("resend-link", payload);
    },
    clearOtpInterval(timeRemaining) {
      this.timeRemaining = timeRemaining;
      clearInterval(this.otpTimer);
      this.otpTimer = null;
    },
    setupOtpInterval() {
      this.timeRemaining = "60";
      this.otpTimer = setInterval(() => {
        let timeRemaining;
        try {
          timeRemaining = parseInt(this.timeRemaining);
        } catch (NanException) {
          timeRemaining = "";
        }
        if (timeRemaining === "") {
          this.clearOtpInterval(timeRemaining);
          return;
        }

        timeRemaining = timeRemaining - 1;

        let slug = timeRemaining.toString();
        if (slug.length < 2) {
          slug = "0" + slug;
        }
        this.timeRemaining = slug;

        if (timeRemaining <= 0) {
          this.clearOtpInterval("");
        }
      }, 1000);
    },
    open(newValue, oldValue) {
      if (newValue) {
        this.setupOtpInterval();
      } else {
        this.clearOtpInterval("");
      }
    },

    setupForm() {
      this.userEmail = this.input;

      let input = document.getElementById("userEmail");
      input.style.backgroundColor = "#5C5C5D";
      input.style.opacity = "0.3";
    },
    pinCompleted(pin) {
      this.profilePin = pin;
      // this.emailVerify()
    },
    updatePin(value) {
      this.userPin = value.split("");
    },

    changeFrom(data) {
      let info = {
        formType: "lookup",
        inputtype: data,
      };
      this.$emit("change", info);
    },

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },
    emailVerify() {
      if (!this.verifyOtp(this.profilePin)) return;
      const payload = {
        email: this.userEmail,
        otp: this.profilePin,
        providerid: _providerId,
      };

      this.clearOtpInterval();
      this.newEmailconfirm(payload)
        .then((response) => {
          if (response.data && response.data.success) {
            let subscriberLoginPayload = {
              app: "SELF",
              params: {
                email: this.userEmail.trim(),
                password: localStorage.getItem("usersignuppassword"),
                devicetype: "PC",
                deviceos: this.getDeviceOS(),
                country: this.country.CountryCode,
              },
            };
            eventBus.$emit("subscriberLogin", subscriberLoginPayload);
          } else if (
            response.data &&
            response.data.reason === "Incorrect OTP"
          ) {
            this.formError = this.$t("Incorrect OTP. Please try again");
            this.$refs.pinLayout.clearOtpBoxes();
            return;
          } else {
            this.formError = this.$t("Incorrect OTP. Please try again");
            this.$refs.pinLayout.clearOtpBoxes();
            return;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.formError = this.$t("Incorrect OTP. Please try again");
          this.$refs.pinLayout.clearOtpBoxes();
          return;
        });
    },

    verifyOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = this.$t("Please Enter OTP");
        return false;
      }
      return true;
    },
  },
  components: {
    PinLayout: () => import("@/components/utils/PinLayout.vue"),
  },
  mixins: [Utility],
};
</script>
  
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./emailVerification.scss";
</style>
  